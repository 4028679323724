import { graphql } from "gatsby";
import React, { useContext } from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import {
  Divider,
  GetStarted,
  ResourcesSuggestions,
  SectionWrapper,
} from "../components/sections";
import YouTube from "react-youtube";
import { SiteContext } from "../components/global/site-context";
import { BackToZone } from "../components/back-to-zone";
import { mapEdgesToNodes } from "../lib/helpers";
import { pageDocPath } from "../../helpers";
import { CardGrid } from "../components/card/card-grid";
import { Button } from "../components/button";
import { ArrowLeft } from "../components/icon/arrow-left";

import * as styles from "./video.module.css";

export const videoMetaData = (v) => {
  return {
    ...v,
    imageUrl: `https://img.youtube.com/vi/${v.videoId}/hqdefault.jpg`,
    url: pageDocPath({ ...v, _type: "video" }),
    _type: "video",
    isVideo: true,
    publishedAt: v.publishDate,
    tags: ["community", "devRel", "gtm"].filter((t) => v[t] === "1"),
  };
};

export const query = graphql`
  query VideoBulkTemplateQuery($id: String!, $series: String!) {
    doc: googleSpreadsheetWwWebsiteContentVideos(videoId: { eq: $id }) {
      title
      videoId
      playlist
      description
      community
      devRel
      gtm
      publishDate
      series
    }
    series: allSanityCategory(limit: 1, filter: { _id: { eq: $series } }) {
      edges {
        node {
          _type
          title
          titlePublic
          slug {
            current
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          customPath
        }
      }
    }
    seriesVideos: allSanityVideo(
      limit: 4
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        categories: { elemMatch: { _id: { eq: $series } } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodeVideo
        }
      }
    }
    seriesVideosBulk: allGoogleSpreadsheetWwWebsiteContentVideos(
      limit: 4
      sort: { fields: [publishDate], order: DESC }
      filter: {
        videoId: { ne: $id }
        status: { eq: "published" }
        series: { eq: $series }
      }
    ) {
      edges {
        node {
          videoId
          slug
          status
          series
          title
          playlist
          community
          devRel
          gtm
          publishDate
        }
      }
    }
    seriesPosts: allSanityPost(
      limit: 4
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        categories: { elemMatch: { _id: { eq: $series } } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

const VideoBulkTemplate = (props) => {
  const { data = {} } = props;
  const { title, videoId } = data.doc || {};

  return (
    <LayoutContainer doc={data.doc}>
      <PageHeadMeta
        title={`${title} [video]`}
        image={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
        doc={data.doc}
      />
      <VideoPage {...data.doc} data={data} />
    </LayoutContainer>
  );
};

export default VideoBulkTemplate;

const VideoPage = ({
  title,
  videoId,
  playlist,
  description,
  data: { series, seriesVideos, seriesVideosBulk },
}) => {
  const site = useContext(SiteContext);
  const seriesResults = mapEdgesToNodes(series);
  const seriesDocs = []
    .concat(mapEdgesToNodes(seriesVideosBulk).map(videoMetaData))
    .concat(mapEdgesToNodes(seriesVideos));

  const seriesNode = seriesResults[0];
  const seriesPath = seriesNode ? pageDocPath(seriesNode) : null;
  const seriesTitle = seriesNode?.titlePublic || seriesNode?.title;

  return (
    <>
      <SectionWrapper container="thin" padding="none">
        <div className="pt-24 mb-12">
          {seriesDocs.length > 0 ? (
            <div className="text-secodary mb-8">
              <Button
                text="Explore series"
                href={seriesPath}
                icon={<ArrowLeft size="12" />}
                bgColor="bg-dark-10"
              />
            </div>
          ) : (
            <BackToZone to="/resources/" text={site.token("backToResources")} />
          )}
          <div className="rounded-xl overflow-hidden">
            <div className={styles.videoWrapper}>
              <YouTube
                videoId={videoId}
                opts={{
                  playerVars: { list: playlist, controls: 0, rel: 0 },
                }}
              />
            </div>
          </div>
          <h1 className="mt-6 mb-2 text-xl font-semibold">{`${title} [video]`}</h1>
          <p>{description}</p>
        </div>
        <Divider />
      </SectionWrapper>

      {seriesDocs.length > 0 ? (
        <SectionWrapper
          layout="centered"
          container="thin"
          header={`${seriesTitle} series`}
        >
          <CardGrid
            maxColumns={2}
            nodes={seriesDocs.slice(0, 4)}
            panels={true}
            hideEvents={true}
            attribution={{ action: "suggestion", label: "video-series" }}
            browseMoreHref={seriesPath}
            browseMoreText="Explore series"
            hideType={true}
            showSubTags={true}
            showExtended={true}
            hideDate={true}
          />
        </SectionWrapper>
      ) : (
        <SectionWrapper
          header={site.token("resourcesSuggestions")}
          anchor="more"
          layout="centered"
          container="thin"
        >
          <ResourcesSuggestions exclude={videoId} maxColumns={2} />
        </SectionWrapper>
      )}

      <SectionWrapper container="large" padding="bottom" background="mint">
        <GetStarted />
      </SectionWrapper>
    </>
  );
};
